import style from './Cta.module.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
import { Fade } from 'react-reveal';
import { useIntersectionObserver } from 'usehooks-ts';
import cubeImg1 from './assets/cube_1.png';
import cubeImg2 from './assets/cube_2.png';
import cubeImg3 from './assets/cube_3.png';

gsap.registerPlugin(ScrollTrigger);

const CtaSection = ({ title, text }) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <section ref={ref} className={style.cta}>
      <div className='container'>
        <div className={style.ctaBody}>
          <div className={style.ctaBody__title}>
            <Fade spy={isVisible} bottom>
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Fade>
          </div>
          <div className={style.ctaBody__text}>
            <Fade spy={isVisible} bottom>
              {text}
            </Fade>
          </div>
          <Fade spy={isVisible} bottom>
            <a href='' className={style.ctaBody__btn}>
              Contact Us
            </a>
          </Fade>

          <div className={style.ctaBody__img}>
            <div className={style.ctaBody__imgItem}>
              <img src={cubeImg1} alt='' />
            </div>
            <div className={style.ctaBody__imgItem}>
              <img src={cubeImg2} alt='' />
            </div>
            <div className={style.ctaBody__imgItem}>
              <img src={cubeImg3} alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
