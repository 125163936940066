import React from 'react';
import MainSection from './Modules/Main';
// import AdvantagesSection from './Modules/Advantages';
// import ApproachSection from './Modules/Approach';
// import InfrastructureSection from './Modules/Infrastructure';
// import MapSection from './Modules/Map';
// import TeamSection from './Modules/Team';
import style from './Styles.module.scss';
import CtaSection from '../../components/Cta';

const AdvantagesSection = React.lazy(() => import('./Modules/Advantages'));
const ApproachSection = React.lazy(() => import('./Modules/Approach'));
const InfrastructureSection = React.lazy(() =>
  import('./Modules/Infrastructure')
);
const MapSection = React.lazy(() => import('./Modules/Map'));
const TeamSection = React.lazy(() => import('./Modules/Team'));

const HomePage = () => {
  return (
    <>
      <div className={style.content}>
        <MainSection />
        <AdvantagesSection />
        <ApproachSection />
        <MapSection />
        {/* <TeamSection /> */}
        <InfrastructureSection />
        <CtaSection
          title={'Contact <span>Us</span>'}
          text={
            "We welcome your inquiries. Reach out and we'll be in touch soon!"
          }
        />
      </div>
    </>
  );
};

export default HomePage;
