import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/Home/Index';
import Header from './components/Header/Index';
import Footer from './components/Footer/Index';
import Fluid from './lib/fluid';
import style from './Index.module.scss';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';

const ManagersPage = React.lazy(() => import('./pages/Managers'));
const CareersPage = React.lazy(() => import('./pages/Careers'));
const PrivacyPage = React.lazy(() => import('./pages/Privacy'));

function App() {
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

      if ('IntersectionObserver' in window) {
        var lazyVideoObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (
                  typeof videoSource.tagName === 'string' &&
                  videoSource.tagName === 'SOURCE'
                ) {
                  videoSource.src = videoSource.dataset.src;
                }
              }

              video.target.load();
              video.target.classList.remove('lazy');
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });

        lazyVideos.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    });
  }, []);

  useEffect(() => {
    viewportUnitsBuggyfill.init({ force: true });
  }, []);

  return (
    <div className={`wrapper`}>
      <div className={`${style.content} content`}>
        <Fluid className={`${location.pathname !== '/' && style.hidden}`} />
        {/* {location.pathname === '/' && <Fluid />} */}
        {/* <Fluid /> */}

        {/* {location.pathname !== '/' && (
          <div
            className={`${style.content__video} ${
              location.pathname === '/asset-managers' && style.managers
            }`}
          >
            {location.pathname === '/careers' && (
              <video
                className='lazy'
                src={videoBg}
                playsInline
                webkit-playsInline
                loop
                autoPlay
                muted
                preload='none'
              ></video>
            )}

            {location.pathname === '/asset-managers' && (
              <>
                <video
                  className='lazy'
                  src={video1Bg}
                  playsInline
                  webkit-playsInline
                  loop
                  autoPlay
                  muted
                  preload='none'
                ></video>
              </>
            )}
          </div>
        )} */}

        <Header />

        <Suspense fallback={<div className=''></div>}>
          <Routes>
            <Route path='*' element={<HomePage />} />
            <Route path='asset-managers' element={<ManagersPage />} />
            <Route path='careers' element={<CareersPage />} />
            <Route path='privacy' element={<PrivacyPage />} />
          </Routes>
        </Suspense>
      </div>

      <Footer />
    </div>
  );
}

export default App;
