import { Link, useLocation, useNavigate } from 'react-router-dom';
import style from './Styles.module.scss';
import logoSvg from './assets/logo.svg';
import { LinksArray } from './Links';
import { useMediaQuery } from 'usehooks-ts';
import { useEffect, useRef, useState } from 'react';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const [scrollToFlag, setScrollToFlag] = useState(false);
  const [scrollPrev, setScrollPrev] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const location = useLocation();

  const navTo = (link) => {
    setBurgerOpen(false);
    navigate(link);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 0);
  };

  const linkList = () => {
    return LinksArray.map((item, index) => (
      <div
        onClick={() => navTo(item.to)}
        key={index}
        className={style.headerBody__linksItem}
      >
        {item.name}
      </div>
    ));
  };

  const linkListMobile = () => {
    return LinksArray.map((item, index) => (
      <div
        onClick={() => navTo(item.to)}
        key={index}
        className={style.headerBody__menuItem}
      >
        <span className={style.num}>0{index + 1}</span>
        {item.name}
        <span className={style.wrap}>
          <span className={style.num}>0{index + 1}</span>
          {item.name}
        </span>
      </div>
    ));
  };

  const headerToggle = () => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setScrollToFlag(true);
      } else {
        setScrollToFlag(false);
      }
      // setScrolled(window.scrollY);

      // if (scrolled > 100 && scrolled > scrollPrev) {
      //   setScrollToFlag(true);
      // } else {
      //   setScrollToFlag(false);
      // }

      // setScrollPrev(scrolled);
    });
  };

  useEffect(() => {
    headerToggle();
  }, [scrolled, location]);

  return (
    <header
      ref={headerRef}
      className={`${style.header} ${scrollToFlag && style.scrolled}`}
    >
      <div className='container'>
        <div className={style.headerBody}>
          <div onClick={() => navTo('')} className={style.headerBody__logo}>
            <img src={logoSvg} alt='' />
          </div>

          {!isMobile && (
            <div className={style.headerBody__links}>{linkList()}</div>
          )}

          {isMobile && (
            <div
              onClick={() => setBurgerOpen(true)}
              className={style.headerBody__burger}
            >
              <span></span>
              <span></span>
            </div>
          )}

          {isMobile && (
            <div
              className={`${style.headerBody__menu} ${
                isBurgerOpen && style.open
              }`}
            >
              <div className='container'>
                <div className={style.headerBody__menuTop}>
                  <div className={style.headerBody__menuTitle}>
                    <span>Menu</span>Menu
                  </div>
                  <div
                    onClick={() => setBurgerOpen(false)}
                    className={style.headerBody__menuClose}
                  >
                    <span></span>
                  </div>
                </div>
                <div className={style.headerBody__menuBody}>
                  {linkListMobile()}
                </div>
                <div className={style.headerBody__menuCr}>
                  <img src={logoSvg} alt='' />
                  <span>
                    <img src={logoSvg} alt='' />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
